import { inject, observer } from 'mobx-react'
import { RouteProps } from 'react-router'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { Box, Spinner, Center } from '@chakra-ui/react'
import { IRootStore } from '../../store'
import Account from '../account/index'
import BotProfile from '../bot-profile'
import BotPrompts from '../bot-prompts'
import Branding from '../branding/'
import Connect from '../connect'
import Dashboard from '../dashboard'
import Engagements from '../engagements'
import Feedback from '../feedback'
import Integrations from '../integrations/integrations'
import Login from '../login'
import Invite from '../onboarding/invite'
import OnboardingStep1 from '../onboarding/step1'
import AgentOne from '../agent-one'
import NotFound from '../not-found'
import PrivacyPolicy from '../privacy-policy'
import Support from '../support'
import MainNavigation from './main-navigation'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useStores } from '../../store/useStores'
import BotAgentOne from '../bot-agent-one'
import { FeatureFlags } from '../../utils/featureFlags'
import { observer as mobxObserver } from 'mobx-react-lite'

interface IRouterProps extends RouteProps {
  store?: IRootStore
}

interface IRouteProviderState {
  isPersistentDrawerMaximized: boolean
  searchQuery: string
  viewMode: 'grid' | 'table'
}

const AgentOneComponent = mobxObserver(() => {
  const { store } = useStores()
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    // Check if organization data is available
    if (store?.organizations?.current) {
      setIsLoading(false)
    }
  }, [store?.organizations, setIsLoading])

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  // Only check features after we confirm organization data is loaded
  // Otherwise there will be a race condition where AgentOne will not render
  const hasAgentOneFeature = store?.organizations?.current?.featuresEnabled([
    FeatureFlags.AGENT_ONE,
  ])

  return hasAgentOneFeature ? <AgentOne /> : <Redirect to="/not-found" />
})

export const PrivateRoute = observer((props: IRouterProps) => {
  const { ...rest } = props
  const { store } = useStores()
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    (async () => {
      if (store?.session && !store?.session.isBootstrapped) {
        if (!isLoading && isAuthenticated) {
          try {
            const accessToken = await getAccessTokenSilently()
            store?.session.setToken(accessToken)
            await store?.fetchBaseData()
            store?.session.setIsBootstrapped(true)
          } catch (error) {
            console.error('Error bootstrapping session:', error)
            store?.logout()
            store?.session.setIsBootstrapped(true)
          }
        }
        if (!isLoading && !isAuthenticated) {
          store?.logout()
          store?.session.setIsBootstrapped(true)
        }
      }
    })()
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    store,
    store?.session?.isAuthenticated,
    store?.session.isBootstrapped,
  ])

  if (!store?.session?.isBootstrapped) {
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    )
  }

  return isAuthenticated ? (
    <Route {...(rest as any)} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
      }}
    />
  )
})

@inject('store')
@observer
export class RouteProvider extends React.Component<
  IRouterProps,
  IRouteProviderState
> {
  private unlistenHistory!: () => void

  constructor(props: IRouterProps) {
    super(props)

    this.state = {
      isPersistentDrawerMaximized: true,
      searchQuery: '',
      viewMode: localStorage.getItem('viewMode') === 'table' ? 'table' : 'grid',
    }
  }

  handleSearchChange = (query: string) => {
    this.setState({ searchQuery: query })
  }

  public componentDidMount() {
    if (!this.state.isPersistentDrawerMaximized) {
      this.toggleDrawer()
    }
  }

  componentWillUnmount() {
    // Remove the history listener
    if (this.unlistenHistory) {
      this.unlistenHistory()
    }
  }

  public toggleDrawer = () => {
    const { store } = this.props
    this.setState((prevState) => ({
      isPersistentDrawerMaximized: !prevState.isPersistentDrawerMaximized,
    }))
    // set session nav drawer to newly set state
    store?.session.setNavDrawerOpen(!this.state.isPersistentDrawerMaximized)
  }

  public toggleViewMode = (mode: 'grid' | 'table') => {
    this.setState({ viewMode: mode })
    localStorage.setItem('viewMode', mode)
  }

  public getMainContentMaxWidth = () => {
    const { store } = this.props

    if (!store?.session.isAuthenticated) {
      return '100%'
    }
    return this.state.isPersistentDrawerMaximized
      ? 'calc(100vw - 350px)'
      : 'calc(100vw - 72px)'
  }

  public render() {
    const { store } = this.props

    if (!store) {
      return null
    }

    const isAgentOnePage =
      store.history.location.pathname.includes('/agent-one')

    return (
      <Router history={store.history}>
        <Box position="relative" top="85px">
          <MainNavigation
            isPersistentDrawerMaximized={this.state.isPersistentDrawerMaximized}
            toggleDrawer={this.toggleDrawer}
            searchQuery={this.state.searchQuery}
            onSearchChange={this.handleSearchChange}
            toggleViewMode={this.toggleViewMode}
            viewMode={this.state.viewMode}
          />
          <Box
            as="main"
            ml="auto"
            mr="0"
            maxWidth={isAgentOnePage ? '100%' : this.getMainContentMaxWidth()}
            transition={isAgentOnePage ? 'none' : 'max-width 0.3s ease-in-out'}
          >
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/signup/step1" component={OnboardingStep1} />
              <Route path="/signup/invite" component={Invite} />
              <PrivateRoute
                exact={true}
                path="/"
                render={(props) => (
                  <Dashboard
                    {...props}
                    searchQuery={this.state.searchQuery}
                    viewMode={this.state.viewMode}
                    toggleViewMode={this.toggleViewMode}
                  />
                )}
              />
              <PrivateRoute
                exact={true}
                path="/dashboard"
                render={(props) => (
                  <Dashboard
                    {...props}
                    searchQuery={this.state.searchQuery}
                    viewMode={this.state.viewMode}
                    toggleViewMode={this.toggleViewMode}
                  />
                )}
              />

              <PrivateRoute
                path="/bot/:botId/bot-prompts"
                component={BotPrompts}
              />
              <PrivateRoute path="/agent-one" component={AgentOneComponent} />
              <PrivateRoute path="/bot/:botId/branding" component={Branding} />
              <PrivateRoute path="/bot/:botId/connect" component={Connect} />
              <PrivateRoute
                path="/bot/:botId/integrations"
                component={Integrations}
              />
              <PrivateRoute
                path="/bot/:botId/component/feedback"
                component={Feedback}
              />
              <PrivateRoute
                path="/bot/:botId/agent-one"
                component={BotAgentOne}
              />
              <PrivateRoute
                path="/bot/:botId/component/profile"
                component={BotProfile}
              />
              <PrivateRoute
                path="/bot/:botId/component/privacypolicy"
                component={PrivacyPolicy}
              />
              <PrivateRoute path="/support" component={Support} />
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/engagements" component={Engagements} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Box>
        </Box>
      </Router>
    )
  }
}
