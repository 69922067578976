import React from 'react'
import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

const NotFound: React.FC = () => {
  const history = useHistory()

  return (
    <Box
      p={8}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <VStack spacing={6} textAlign="center" maxW="md">
        <Heading size="2xl" className="text-gray-700">
          404
        </Heading>

        <Heading size="lg" className="text-gray-600">
          Page Not Found
        </Heading>

        <Text className="text-gray-500">
          The page you're looking for doesn't exist or has been moved.
        </Text>

        <Box pt={4}>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={() => history.push('/dashboard')}
          >
            Return to Dashboard
          </Button>
        </Box>
      </VStack>
    </Box>
  )
}

export default NotFound
