import axios from 'axios'

import {
  GreeterStyle,
  IBotPrompt,
  IExperiment,
  IFetchAgentsResult,
  ILanguageObject,
} from '../store/bots'
import Logger from '../utils/logger'

const log = Logger('portal/api/bots').log

const BOTS_DEFAULT_PAGE = 1
const BOTS_DEFAULT_PER_PAGE = 200
export const getBots = async (sort: string): Promise<{ bots: [] }> => {
  const response = await axios.get(
    `/portal/bots?perPage=${BOTS_DEFAULT_PER_PAGE}&page=${BOTS_DEFAULT_PAGE}&sort=${sort}`,
  )
  return (response && response.data) || { bots: [] }
}

export const rotateServiceAccountKey = async () => {
  const response = await axios.get('/bots/rotate-service-account-key')

  return (response && response.data) || {}
}

export interface IPostBotPayload {
  name: string
  platform: string
  dashboardLabel: string | undefined
  dialogflowDataRegion: string
}

export const postBots = async (
  payload: IPostBotPayload,
): Promise<{ [key: string]: any }> => {
  const response = await axios.post('/bots', payload)
  return (response && response.data) || {}
}

export interface GeneratedPrompts {
  prompts: string[] | null
  error: number | null
  oldestGenerationTime: number | null
}

export interface ExperimentResponse {
  success: boolean
  experiment: IExperiment | null
}

export interface IPatchBotPayload {
  bcAvatarBackgroundColor: string
  bcBotResponseFillColor: string
  bcBotResponseBorderColor: string
  bcBotResponseFontColor: string
  bcButtonFillColor: string
  bcButtonFontColor: string
  bcCardButtonTextAlignment: string
  bcChatBackground: string
  bcChatroomFillColor: string
  bcChatWindowType: string
  bcDimensionsWindowHeight: string
  bcDimensionsWindowWidth: string
  bcEnableFocusTrap: boolean
  bcFeedbackAddCommentBackground: string
  bcFeedbackAddCommentFontColor: string
  bcFeedbackSubmitBackground: string
  bcFeedbackSubmitFontColor: string
  bcFocusFillColor: string
  bcFontType: string
  bcGreeterBackground: string
  bcGreeterBorder: string
  bcGreeterBorderHoverColor: string
  bcGreeterFontColor: string
  bcGreeterHoverFillColor: string
  bcGreeterHoverFontColor: string
  bcGreeterImageHeight: string
  bcGreeterImageWidth: string
  bcGreeterAnimationName: string
  bcGreeterAnimationSpeed: string
  bcGreeterAnimationTimingFunction: string
  bcGreeterPromptBackground: string
  bcHeaderFillColor: string
  bcHeaderFontColor: string
  bcHeaderType: string
  bcHeaderSubtitle: string
  bcHoverFillColor: string
  bcHoverFontColor: string
  bcInputBarStyle: string
  bcInputBarBorderColor: string
  bcInputBarContainerFillColor: string
  bcInputBarFillColor: string
  bcInputBarFontColor: string
  bcInputBarLabelColor: string
  bcInputBarPlaceholderColor: string
  bcLiveChatEntrySound: string
  bcMenuButtonColor: string
  bcPrivacyPolicyAcceptFontColor: string
  bcPrivacyPolicyAcceptFillColor: string
  bcPrivacyPolicyAcceptBorderColor: string
  bcPrivacyPolicyDeclineFontColor: string
  bcPrivacyPolicyDeclineFillColor: string
  bcPrivacyPolicyDeclineBorderColor: string
  bcPrivacyPolicyDescriptionFontColor: string
  bcPromptSuggestionFillColor: string
  bcPromptSuggestionFontColor: string
  bcPromptSuggestionHoverFillColor: string
  bcPromptSuggestionHoverFontColor: string
  bcShowAvatar: boolean
  bcShowClearConversationMenuItem: boolean
  bcShowDefaultPromptOnPagesWithoutPrompt: boolean
  bcShowMaximizeButton: boolean
  bcShowMenu: boolean
  bcShowMicrophone: boolean
  bcShowMoreOptionsMenu: boolean
  bcShowPoweredByBotcopy: boolean
  bcShowPrivacyPolicyMenuItem: boolean
  bcShowPromptSuggestions: boolean
  bcShowPrompts: boolean
  bcShowGreeter: boolean
  bcSidebarMarginTop: string
  bcSidebarBorderType: string
  bcSidebarBorderImageLinearGradient: string
  bcTypingAnimationDots: string
  bcTypingAnimationFill: string
  bcUiByBotcopyCircleColor: string
  bcUiByBotcopyToggleColor: string
  bcUiByBotcopyImg: string
  bcUserBoxColor: string
  bcUserResponseColor: string
  bcWidgetShape: string
  bcZIndex: number
  v2: boolean
}

// Patches the bot theme.
// Any new theme properties must be added to the IPatchBotPayload interface
export interface IPatchPayload {
  agent?: any
  name?: string
  active?: boolean
  credentials?: any
  installedOnRestricted?: boolean
  projectId?: string
  serviceEmail?: string
  prompts?: IBotPrompt[]
  theme?: {
    css?: IPatchBotPayload
    defaultGreeterStyle?: GreeterStyle
    images?: {
      avatar?: string
      logo?: string
      typingAvatar?: string
      headerImg?: string
    }
    feedback?: {
      bcAskFeedback?: boolean
      bcFeedbackTitle?: string
      bcFeedbackThankYou?: string
      bcShowCommentButton?: boolean
    }
    botProfile?: any
    privacyPolicy?: {
      bcShowPrivacyPolicy?: boolean
      bcPrivacyPolicyTitle?: string
      bcPrivacyPolicyAcceptLabel?: string
      bcPrivacyPolicyDeclineLabel?: string
      bcPrivacyPolicyDeclineMessage?: string
      bcPrivacyPolicyURL?: string
    }
  }
  integrations?: {
    dashbotApiKey?: string
    dashbotPlatform?: string
    janisApiKey?: string
    janisClientKey?: string
  }
  handoverIntegration?: {
    active?: boolean
    accessToken?: string
    headerValue?: string
    headerName?: string
    triggerContexts?: string[]
    webhook?: string
  }
  languageSelection?: {
    active: boolean
    languages: ILanguageObject[]
  }
  website?: string
  privacyPolicyURL?: string
  newBot?: boolean
  refKeyName?: string
  installedOn?: string[]
  installedOnExceptions?: string[]
  storeHistory?: boolean
  dashboardLabel?: string
  cxAgent?: any
  dialogflow?: {
    location?: string
  }
}

export const deleteBot = async (id: string) => {
  const { data } = await axios.delete(`/bots/delete/${id}`)
  return data
}

export const patchBot = async (id: string, payload: IPatchPayload) => {
  if (id.match(/^[0-9a-fA-F]{24}$/)) {
    log(`PATCH /bots/${id}`, payload)
    const { data } = await axios.patch(`/bots/${id}`, payload)
    return data
  } else {
    return 'Invalid Bot ID'
  }
}

export const patchBotCredentials = async (
  id: string,
  payload: IPatchPayload,
) => {
  if (id.match(/^[0-9a-fA-F]{24}$/)) {
    const { data } = await axios.patch(`/bots/credentials/${id}`, payload)
    return data
  } else {
    return 'Invalid Bot ID'
  }
}

export const getBucket = async (id: string, fileName: string) => {
  const { data } = await axios.get(`/bots/${id}/pre-signed-url/${fileName}`)
  return data
}

// post to s3 bucket
export const postToS3 = async (url: string, photo: any) => {
  const call = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': `application/octet`,
    },
    transformRequest: [
      (data, headers) => {
        delete headers.authorization
        return data
      },
    ],
  })
  return call.put(url, photo)
}

export const getAgents = async (
  page: number,
  perPage: number,
  sort: string,
  location: string,
): Promise<IFetchAgentsResult[]> => {
  const response = await axios.get(
    `/portal/agents?perPage=${perPage}&page=${page}&sort=${sort}&location=${location}`,
  )
  return (response && response.data) || []
}

export const getAlternativePrompts = async (
  prompt: string,
): Promise<GeneratedPrompts> => {
  const payload = { prompt }
  const results: GeneratedPrompts = {
    prompts: null,
    error: null,
    oldestGenerationTime: null,
  }
  try {
    const { data } = await axios.post(`/bot/bot-prompts`, payload)
    results.prompts = data.prompts
  } catch (e: any) {
    const error = e.response
    if (error.status === 403) {
      results.oldestGenerationTime = error.data.oldestGenerationTime
    }
    results.error = error.status
  }

  return results
}

export const createDraftExperiment = async (
  botId: string,
  promptId: string,
  promptMessages: string[],
): Promise<ExperimentResponse> => {
  const payload = { promptMessages }
  const { data } = await axios.post(
    `/bots/${botId}/prompts/${promptId}/experiments`,
    payload,
  )
  return data
}

export const startExperiment = async (
  botId: string,
  promptId: string,
  experimentId: string,
): Promise<ExperimentResponse> => {
  const { data } = await axios.patch(
    `/bots/${botId}/prompts/${promptId}/experiments/${experimentId}/start`,
  )
  return data
}

export const stopExperiment = async (
  botId: string,
  promptId: string,
  experimentId: string,
): Promise<ExperimentResponse> => {
  const { data } = await axios.patch(
    `/bots/${botId}/prompts/${promptId}/experiments/${experimentId}/stop`,
  )
  return data
}
