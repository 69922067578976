import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
  Alert,
} from '@chakra-ui/react'
import { IBot } from '../../store/bots'
import { IRootStore } from '../../store'
import { useSaveDetector } from '@botcopy/ui-shared'
import { CardCustom } from '@botcopy/ui-shared'
import { ConfirmationModal } from '@botcopy/ui-shared'
import { DragAndDropItem, DragAndDropList } from '@botcopy/ui-shared'
import { SaveDetectorConfirmation } from '@botcopy/ui-shared'
import { Notification, Panel } from '@botcopy/ui-shared'
import { observer, inject } from 'mobx-react'

interface BotMenuItem {
  id: number
  name: string
  type: 'internal' | 'external'
  botId?: string
  label?: string
  avatar?: {
    url: string
    name?: string
  }
}

interface IAgentOneProps {
  store?: IRootStore
}

interface ExtendedBot {
  id: number
  name: string
  items: BotMenuItem[]
  availableItems: BotMenuItem[]
  menuTitle: string
}

const AgentOne: React.FC<IAgentOneProps> = ({ store }) => {
  const [selectedBotMenu, setSelectedBotMenu] = useState<ExtendedBot | null>(
    null,
  )
  const [isSaving, setIsSaving] = useState(false)
  const [externalBotId, setExternalBotId] = useState('')
  const [externalBotIdError, setExternalBotIdError] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [pendingBotId, setPendingBotId] = useState<number | null>(null)
  const [botMenus, setBotMenus] = useState<{ [key: number]: ExtendedBot }>({})

  const availableBots: ExtendedBot[] =
    store?.bots.entities.map((e: IBot, i: number) => ({
      id: e._id,
      name: e.name,
      items: [],
      availableItems: [],
      menuTitle: e.name,
    })) || []

  const hasUnsavedChanges = useSaveDetector(
    selectedBotMenu,
    availableBots.find((bot: ExtendedBot) => bot.id === selectedBotMenu?.id),
  )
  const toast = useToast()

  useEffect(() => {
    if (!selectedBotMenu && availableBots.length > 0) {
      setSelectedBotMenu(null)
    }
    logBotsToConsole()
  }, [availableBots, store])

  const logBotsToConsole = () => {
    console.log(availableBots)
  }

  const handleBotSelect = (botId: number) => {
    if (hasUnsavedChanges) {
      setPendingBotId(botId)
      setShowConfirmationModal(true)
    } else {
      selectBot(botId)
    }
  }

  const selectBot = (botId: number) => {
    const selected = availableBots.find((bot: ExtendedBot) => bot.id === botId)
    if (selected) {
      setSelectedBotMenu(selected)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBotMenu((prev: ExtendedBot | null) =>
      prev
        ? {
            ...prev,
            menuTitle: e.target.value,
          }
        : null,
    )
  }

  const handleAddMenuItem = (bot: ExtendedBot) => {
    const menuItem: BotMenuItem = {
      id: Date.now(),
      name: bot.name,
      type: 'internal',
      botId: bot.id.toString(),
      label: bot.id.toString(), // Use bot's ID for label
      avatar: {
        url: 'https://via.placeholder.com/150',
        name: bot.name,
      },
    }

    setSelectedBotMenu((prev: ExtendedBot | null) =>
      prev
        ? {
            ...prev,
            items: [...prev.items, menuItem],
          }
        : null,
    )
  }

  const handleRemoveMenuItem = (itemToRemove: BotMenuItem) => {
    setSelectedBotMenu((prev: ExtendedBot | null) =>
      prev
        ? {
            ...prev,
            items: prev.items.filter(
              (item: BotMenuItem) => item.id !== itemToRemove.id,
            ),
          }
        : null,
    )
  }

  const validateExternalBotId = (id: string): boolean => {
    if (!id.trim()) {
      setExternalBotIdError('Bot ID is required')
      return false
    }

    if (!/^[a-zA-Z0-9]+$/.test(id)) {
      setExternalBotIdError('Bot ID can only contain letters and numbers')
      return false
    }

    setExternalBotIdError('')
    return true
  }

  const handleExternalBotIdChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setExternalBotId(e.target.value)
    if (externalBotIdError) {
      validateExternalBotId(e.target.value)
    }
  }

  const handleExternalBotSubmit = (e?: React.FormEvent) => {
    e?.preventDefault()

    if (!validateExternalBotId(externalBotId)) {
      return
    }

    const newItem: BotMenuItem = {
      id: Date.now(),
      name: 'Keystroke',
      type: 'external',
      botId: externalBotId,
      label: externalBotId, // Use external bot ID for label
      avatar: {
        url: 'https://via.placeholder.com/150',
        name: 'Keystroke',
      },
    }
    setSelectedBotMenu((prev: ExtendedBot | null) =>
      prev
        ? {
            ...prev,
            items: [...prev.items, newItem],
          }
        : null,
    )
    setExternalBotId('')
    setExternalBotIdError('')
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      store?.bots.entities.forEach((bot: IBot, index: number) => {
        if (index === selectedBotMenu?.id) {
          bot.name = selectedBotMenu.menuTitle
        }
      })
      toast({
        title: 'Save Successful',
        description: 'Your changes have been saved.',
        status: 'success',
      })
    } catch (error) {
      console.error('Failed to save:', error)
    } finally {
      setIsSaving(false)
    }
  }

  const handleDragEnd = (items: BotMenuItem[]) => {
    setSelectedBotMenu((prev: ExtendedBot | null) =>
      prev
        ? {
            ...prev,
            items,
          }
        : null,
    )
  }

  const handleConfirmNavigation = () => {
    if (pendingBotId !== null) {
      selectBot(pendingBotId)
      setPendingBotId(null)
    }
    setShowConfirmationModal(false)
  }

  const handleCreateBotMenu = () => {
    const newId = Date.now()
    const newBotMenu: ExtendedBot = {
      id: newId,
      name: `New Bot Menu ${Object.keys(botMenus).length + 1}`,
      items: [],
      availableItems: [],
      menuTitle: `New Bot Menu ${Object.keys(botMenus).length + 1}`,
    }
    setBotMenus((prev) => ({ ...prev, [newId]: newBotMenu }))
    setSelectedBotMenu(newBotMenu)
  }

  return (
    <>
      <Flex flexDirection={{ base: 'column', md: 'row' }}>
        <Panel
          items={Object.values(botMenus).map(
            (bot: ExtendedBot, index: number) => (
              <Notification
                key={index}
                body={bot.name}
                additionalInfo={[
                  {
                    text: `${availableBots.length || 0} bots available`,
                    colorScheme: 'grey',
                  },
                  // {
                  //   text: `X bots connected`,
                  //   colorScheme: 'blue',
                  // },
                ]}
                isSelected={bot.id === selectedBotMenu?.id}
                onClick={() => handleBotSelect(bot.id)}
              />
            ),
          )}
          heading="Bot Menus"
          blockPaddingX={0}
          blockPaddingY={0}
          width="400px"
          height={'calc(100vh - 86px)'}
          actionButton={{
            label: 'Create Bot Menu',
            onClick: handleCreateBotMenu,
          }}
        />
        <Box mx="auto" maxWidth="990px">
          {Object.keys(botMenus).length === 0 && (
            <Alert width="100%" textAlign="left" mt={3}>
              It looks like you don't have any bot menus set-up yet! To get
              started, select the "Create Bot Menu" option from the menu on the
              left.
            </Alert>
          )}
          {selectedBotMenu && (
            <>
              <Heading mb={2} pt={6}>
                {selectedBotMenu.name}
              </Heading>
              <Heading size="sm" fontWeight={400} mb={4}>
                ID #{selectedBotMenu.id}
              </Heading>
              <Grid
                templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                gap={6}
                maxWidth="920px"
                // Add margin bottom to the grid to prevent the save detector cutting off options
                mb={20}
              >
                <GridItem>
                  <CardCustom>
                    <FormControl>
                      <FormLabel>Bot Menu Title</FormLabel>
                      <Input
                        placeholder="Bot Menu Title"
                        value={selectedBotMenu.menuTitle}
                        onChange={handleInputChange}
                      />
                      <FormHelperText>For internal use only</FormHelperText>
                    </FormControl>
                  </CardCustom>
                </GridItem>

                <GridItem>
                  <CardCustom>
                    <FormControl>
                      <FormLabel>Bot Menu Header</FormLabel>
                      <Button>Set & Localize Header</Button>
                      <FormHelperText>For customers</FormHelperText>
                    </FormControl>
                  </CardCustom>
                </GridItem>

                <GridItem>
                  <CardCustom>
                    <Heading size="md" mb={4}>
                      Add Menu Items
                    </Heading>
                    <VStack
                      maxHeight={'400px'}
                      overflowY="scroll"
                      width="100%"
                      maxWidth="430px"
                    >
                      {availableBots.map((bot: ExtendedBot) => (
                        <Flex
                          key={bot.id}
                          alignItems="center"
                          width="100%"
                          justifyContent="space-between"
                        >
                          <Text maxWidth="320px" isTruncated={true}>
                            {bot.name}
                          </Text>
                          <Button onClick={() => handleAddMenuItem(bot)}>
                            Add
                          </Button>
                        </Flex>
                      ))}
                    </VStack>
                    <form onSubmit={handleExternalBotSubmit}>
                      <FormControl mt={6} isInvalid={!!externalBotIdError}>
                        <FormLabel>
                          Add external bot by Botcopy bot ID
                        </FormLabel>
                        <HStack>
                          <Input
                            placeholder="i.e. 6548d5f025abf8129b5abb61"
                            value={externalBotId}
                            onChange={handleExternalBotIdChange}
                            maxLength={24}
                          />
                          <Button type="submit">Add</Button>
                        </HStack>
                        {externalBotIdError && (
                          <FormErrorMessage>
                            {externalBotIdError}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </form>
                  </CardCustom>
                </GridItem>

                <GridItem width="md">
                  <CardCustom>
                    <FormLabel>Drag & Drop to Sort Bot Menu</FormLabel>
                    {selectedBotMenu.items.length === 0 && (
                      <Text color="gray.500" mt={2}>
                        Add menu items to get started
                      </Text>
                    )}
                    <Box maxHeight="500px" overflowY="auto" width="100%">
                      {selectedBotMenu.items.length > 0 && (
                        <DragAndDropList
                          items={
                            selectedBotMenu.items.map((item) => ({
                              ...item,
                              name: item.name,
                              label: `ID #${item.id.toString()}`,
                            })) as DragAndDropItem[]
                          }
                          onReorder={(items) =>
                            handleDragEnd(items as BotMenuItem[])
                          }
                          removableItems={{
                            onRemoveItem: (item) =>
                              handleRemoveMenuItem(item as BotMenuItem),
                          }}
                        />
                      )}
                    </Box>
                  </CardCustom>
                </GridItem>
              </Grid>
            </>
          )}
        </Box>
      </Flex>

      <SaveDetectorConfirmation
        hasUnsavedChanges={hasUnsavedChanges}
        onSave={handleSave}
        isSaveLoading={isSaving}
        boxProps={{
          boxShadow: 'none',
          borderTopWidth: '1px',
          borderTopColor: 'gray.300',
          borderTopStyle: 'solid',
          width: 'calc(100% - 400px)',
          left: '400px',
        }}
      />

      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        header="Unsaved Changes"
        content="You have unsaved changes. Are you sure you want to navigate away and lose these changes?"
        confirmText="Yes, Navigate"
        cancelButtonText="Cancel"
        onConfirm={handleConfirmNavigation}
      />
    </>
  )
}

export default inject('store')(observer(AgentOne))
