import React, { useEffect } from 'react'
import { Box, Heading, Switch } from '@chakra-ui/react'
import { CardCustom } from '@botcopy/ui-shared'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useStores } from '../../store/useStores'

const BotAgentOne: React.FC = observer(() => {
  const { botId } = useParams<{ botId: string }>()
  const { store } = useStores()

  useEffect(() => {
    if (botId) {
      store?.bots.setCurrentBotId(botId)
    }
  }, [botId, store?.bots])

  return (
    <Box maxW="6xl" mx="auto" textAlign="left">
      <Heading as="h1" pt={8} pb={4}>
        Agent One
      </Heading>
      <CardCustom>
        <Switch>Enable AgentOne for this bot</Switch>
      </CardCustom>
    </Box>
  )
})

export default BotAgentOne
